import React from "react";
import { Navbar } from "reactstrap";
import classnames from "classnames";
import NavbarBookmarks from "./NavbarBookmarks";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Bell, Search } from "react-feather";

//import NavbarUser from "./NavbarUser";
//import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg";

const ThemeNavbar = (props) => {
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  const { user } = useSelector((state) => state.auth.login);

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      {/* <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none":
              window.innerWidth > 1200 &&
              props.navbarType === "hidden" &&
              !props.horizontal,
            "floating-nav":
              window.innerWidth <= 1200 ||
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="d-flex align-items-center">
                <div className="bookmark-wrapper">
                  <NavbarBookmarks
                    sidebarVisibility={props.sidebarVisibility}
                    handleAppOverlay={props.handleAppOverlay}
                  />
                </div>
                <img
                  src="/logo.png"
                  alt="lubrico"
                  style={{ maxHeight: "35px", marginRight: "10px" }}
                />
              </div>
              <div
                className="d-flex align-items-center pr-1"
                style={{ gap: "20px" }}
              >
                <Search size={18} color="#fff" />
                <Link href="/notifications">
                  <Bell color="#fff" size={18} />
                </Link>
                <img
                  src={`${user.avatar}`}
                  alt={user.name}
                  width={40}
                  style={{ borderRadius: "50%", border: "1px solid #fff" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Navbar> */}
    </React.Fragment>
  );
};

export default ThemeNavbar;
