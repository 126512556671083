import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "Menü",
    permissions: ["admin", "editor", "lead", "customer", "admin_assistant"],
  },
  // {
  // 	id: 'customer-tasks',
  // 	title: 'Tasks',
  // 	type: 'item',
  // 	icon: <Icon.Trello size={20} />,
  // 	permissions: ['customer'],
  // 	navLink: '/customer-tasks',
  // },
  {
    id: "my-tasks",
    title: "Görevlerim",
    type: "item",
    icon: <Icon.Trello size={20} />,
    permissions: ["admin", "editor", "lead", "customer", "admin_assistant"],
    navLink: "/my-tasks",
  },
  {
    id: "onaylar",
    title: "Onaylar",
    type: "item",
    icon: <Icon.Check size={20} />,
    permissions: ["admin", "editor", "lead", "customer", "admin_assistant"],
    navLink: "/onaylar",
  },
  {
    id: "time",
    title: "Zaman Takibi",
    type: "item",
    icon: <Icon.Clock size={20} />,
    permissions: ["admin", "editor", "lead"],
    navLink: "/time",
  },
  {
    id: "rutinler",
    title: "Rutinler",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["admin", "editor", "lead", "customer", "admin_assistant"],
    navLink: "/rutinler",
  },
  // {
  // 	id: 'reports',
  // 	title: 'Reports',
  // 	type: 'item',
  // 	icon: <Icon.PieChart size={20} />,
  // 	permissions: ['admin', 'editor', 'lead'],
  // 	navLink: '/reports',
  // },
  // {
  // 	id: 'expenses',
  // 	title: 'Expenses',
  // 	type: 'item',
  // 	icon: <Icon.CreditCard size={20} />,
  // 	permissions: ['admin', 'editor', 'lead'],
  // 	navLink: '/expenses',
  // },
  {
    id: "people",
    title: "Ekip",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor", "lead", "admin_assistant"],
    navLink: "/people",
  },
  {
    id: "notifications",
    title: "Bildirimler",
    type: "item",
    icon: <Icon.Bell size={20} />,
    permissions: ["admin", "editor", "lead", "admin_assistant"],
    navLink: "/notifications",
  },
  // {
  // 	id: 'hyperbot',
  // 	title: 'Hyperbot',
  // 	type: 'item',
  // 	icon: <Icon.Aperture size={20} />,
  // 	permissions: ['admin'],
  // 	navLink: '/hyperbot',
  // },
  {
    type: "groupHeader",
    groupTitle: "Yönetim",
    permissions: ["admin", "admin_assistant"],
  },
  {
    id: "settings",
    title: "Ayarlar",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "admin_assistant"],
    navLink: "/settings",
  },
  // {
  // 	id: 'invoices',
  // 	title: 'Invoices',
  // 	type: 'item',
  // 	icon: <Icon.FileText size={20} />,
  // 	permissions: ['admin', 'lead'],
  // 	navLink: '/invoices',
  // },
  {
    id: "agreements",
    title: "Sözleşmeler",
    type: "item",
    icon: <Icon.Book size={20} />,
    permissions: ["admin", "lead", "admin_assistant"],
    navLink: "/sozlesmeler",
  },
  // {
  // 	id: 'workload',
  // 	title: 'Workload',
  // 	type: 'item',
  // 	icon: <Icon.BarChart2 size={20} />,
  // 	permissions: ['admin'],
  // 	navLink: '/workload',
  // },
  // {
  // 	id: 'csv',
  // 	title: 'CSV',
  // 	type: 'item',
  // 	icon: <Icon.PieChart size={20} />,
  // 	permissions: ['admin'],
  // 	navLink: '/csv',
  // },
  // {
  // 	id: 'adminReport',
  // 	title: 'Reports',
  // 	type: 'external-link',
  // 	icon: <Icon.PieChart size={20} />,
  // 	permissions: ['editor', 'admin', 'lead'],
  // 	navLink: 'https://hyperactive.pro/admin/reports',
  // },
];

export default navigationConfig;
