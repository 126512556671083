const Statuses = [
  {
    id: "1",
    title: "Açık",
    colorCode: "#b8c2cc",
    variant: "warning",
  },
  {
    id: "2",
    title: "Yapılıyor",
    colorCode: "#ff9f43",
    variant: "success",
  },
  {
    id: "3",
    title: "Tamamlandı",
    colorCode: "#699bf7",
    variant: "primary",
  },
  {
    id: "5",
    title: "Kapatıldı",
    colorCode: "#28c76f",
    variant: "danger",
  },
];

const STATUSES_ENUM = {
  OPEN: "1",
  INPROGRESS: "2",
  DONE: "3",
  REVIEW: "4",
  COMPLETED: "5",
};

export { Statuses, STATUSES_ENUM };
