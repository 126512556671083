import React from "react";
import "../../../assets/scss/logo-loading.scss";

function LogoLoading() {
  return (
    <div className="vh-100">
      <div className="loading-logo">
        <div className="loading-text">
          <span className="loading-text-words">l</span>
          <span className="loading-text-words">u</span>
          <span className="loading-text-words">b</span>
          <span className="loading-text-words">r</span>
          <span className="loading-text-words">i</span>
          <span className="loading-text-words">c</span>
          <span className="loading-text-words">o</span>
        </div>
      </div>
    </div>
  );
}

export default LogoLoading;
